import React from 'react';
import BulletPoint from '../bulletPoint/bulletPoint';

const bulletPointList = (props) => {
    return (
        <>
            <div className="bulletPointItem"><BulletPoint text={props.list[0]} color="rgba(175, 99, 180, 1)" /></div>
            <div className="bulletPointItem"><BulletPoint text={props.list[1]} color="rgba(134, 57, 139, 1)" /></div>
            <div className="bulletPointItem"><BulletPoint text={props.list[2]} color="rgba(84, 19, 87, 1)" /></div>
        </>
    );
}

export default bulletPointList;
