import React from 'react';

const hero = (props) => {

    const backgroundConfig = {
        backgroundImage: ` linear-gradient(
                to right,
                rgba(136, 37, 134, 0.2),
                rgba(136, 37, 134, 0.2)),
            url(` + props.image + `)`,
    };

    if (props.position) {
        backgroundConfig.backgroundPosition = props.position;
    }

    return (
        <div style={backgroundConfig}  className="hero">
            <div className="hero__textBox">
                { props.children }
            </div>
        </div>
    );
};

export default hero;
