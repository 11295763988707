import React from "react"

import Hero from '../components/hero/hero';
import Button from '../components/button/button';
import IndustryBox from '../components/industryBox/industryBox';
import AppSlider from '../components/appSlider/appSlider'
import BulletPointList from '../components/bulletPointList/bulletPointList'
import SocialBar from '../components/socialBar/socialBar'
import Layout from '../components/layout/layout'

import pillBottleImage from '../assets/pill-bottle.png';
import healthInsuranceIcon from '../assets/microscope.svg';
import pharmaIcon from '../assets/medicine.svg';
import hospitalIcon from '../assets/cardiogram.svg';
import specialtyIcon from '../assets/medical-history.svg';
import livitLogo from '../assets/livit.svg';
import ipadImage from '../assets/ipad.png';
import HeroPreOrder from "components/heroPreOrder/heroPreOrder";

const homePage = () => {
    return (
        <>
            <Layout>
                <HeroPreOrder>
                </HeroPreOrder>
                <main>
                    <section className="mission">
                        <div className="u-center-text u-margin-bottom-small">
                            <h2 className="heading-secondary">Our Mission</h2>
                        </div>
                        <div className="mission__content">
                            <div className="mission__writtenContent">
                                <p className="paragraph u-center-text">
                                    The American healthcare system is fraught with waste, inefficiency,
                                    and a lack of focus on the patient. Together, these challenges add
                                    up to skyrocketing costs and diminished standards of care.

                                    But what if these problems could be solved — all of them — through simple, elegant technology?
                                </p>
                                <p className="paragraph u-center-text">
                                    Virtual Care technology can streamline the healthcare system, reducing
                                    unnecessary time and cost. It can also make it better for patients,
                                    bringing them closer to their providers and creating a higher
                                    level of care.
                                </p>
                                <div className="u-center-text">
                                    <Button className="button--primaryBorder button--small" to="/about">Learn More</Button>
                                    <div className="mission__socialBar">
                                        <SocialBar />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="products">
                        <div className="products__bg"></div>
                        <div className="u-center-text u-margin-bottom-small">
                            <div className="products__logoBox">
                                <img src={livitLogo} />
                            </div>
                        </div>
                        <div className="products__content-1 u-margin-bottom-large">
                            <div className="products__display products__display--images--phone">
                                <div className="products__phoneSlider">
                                    <AppSlider></AppSlider>
                                </div>
                                <div className="products__pillBottleContainer">
                                    <img src={pillBottleImage} className="products__pillBottle" />
                                </div>
                            </div>
                            <div className="products__display">
                                <h2 className="heading-secondary u-margin-bottom-very-small">Virtual Care System</h2>
                                <p className="paragraph">
                                    Through our hardware and software applications, we're working to build a complete Virtual Care System
                                    that makes healthcare providers smarter and more connected – digital clinics that serve the
                                    needs of patients better.
                                </p>
                                <BulletPointList list={['Precision Care', 'Individual Focus', 'Universal Challenges']} />
                                <div className="u-margin-bottom-large"></div>
                                <Button className="button--primaryBorder button--small" to="/products">Learn More</Button>
                            </div>
                        </div>
                        <div className="products__content-2">
                            <div className="products__display">
                                <h2 className="heading-secondary u-margin-bottom-very-small">Real-Time Data</h2>
                                <p className="paragraph">
                                    Get real-time data and automatic reports from your paired LIVIT devices and application.
                                </p>
                                <BulletPointList list={['Smart Tracking', 'Vital Tracking', 'Interactive Care']} />
                                <div className="u-margin-bottom-large"></div>
                                <Button className="button--primaryBorder button--small" to="/products">Learn More</Button>
                            </div>
                            <div className="products__display products__display--images--ipad">
                                <div className="products__ipadContainer">
                                    <img src={ipadImage} className="products__ipad" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="industries">
                        <div className="u-center-text u-margin-bottom-small">
                            <h2 className="heading-secondary">Industries we serve</h2>
                        </div>
                        <div className="industries__list">
                            <div className="industry__item"><IndustryBox icon={specialtyIcon} title1="Specialty" title2="Healthcare" /></div>
                            <div className="industry__item"><IndustryBox icon={pharmaIcon} title1="Pharma" title2="Pharmaceutical" /></div>
                            <div className="industry__item"><IndustryBox icon={hospitalIcon} title1="Hospitals" title2="Clinics" /></div>
                            <div className="industry__item"><IndustryBox icon={healthInsuranceIcon} title1="Health" title2="Insurance" /></div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    );
};

export default homePage;

