import React from 'react';

const industryBox = (props) => {
  return (
    <div className="industryBox">
      <div className="industryBox__iconContainer">
        <img className="industryBox__icon" src={props.icon} alt="icon" />
      </div>
      <h3 className="heading-tertiary">{ props.title1 }</h3>
      <h3 className="heading-tertiary u-margin-bottom-very-small">{ props.title2 }</h3>
    </div>
  )

};

export default industryBox;
