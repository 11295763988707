import React, { Component } from 'react';

const getCountDownData = (endDate) => {

    const msPerDay = 1000 * 60 * 60 * 24;
    const msPerHour = 1000 * 60 * 60;
    const msPerMinute = 1000 * 60;
    const msPerSecond = 1000;

    const now = new Date();

    let msUntilRelease = endDate.getTime() - now.getTime();

    if (msUntilRelease < 0) return {
        days: null,
        hours: null,
        minutes: null,
        seconds: null
    }

    let daysUntilRelease = Math.floor(msUntilRelease / msPerDay);
    msUntilRelease = msUntilRelease - daysUntilRelease * msPerDay;

    let hoursUntilRelease = Math.floor(msUntilRelease / msPerHour);
    msUntilRelease = msUntilRelease - hoursUntilRelease * msPerHour;

    let minutesUntilRelease = Math.floor(msUntilRelease / msPerMinute);
    msUntilRelease = msUntilRelease - minutesUntilRelease * msPerMinute;

    let secondsUntilRelease = Math.floor(msUntilRelease / msPerSecond);
    msUntilRelease = msUntilRelease - secondsUntilRelease * msPerMinute;

    return {
        days: daysUntilRelease,
        hours: hoursUntilRelease,
        minutes: minutesUntilRelease,
        seconds: secondsUntilRelease
    };
}

const countDownOneSecond = (timeRemaining) => {
    if (timeRemaining.seconds !== 0) {
        timeRemaining.seconds = timeRemaining.seconds - 1;
    } else if (timeRemaining.seconds === 0 && timeRemaining.minutes > 0) {
        timeRemaining.minutes = timeRemaining.minutes - 1;
        timeRemaining.seconds = 59;
    } else if (timeRemaining.seconds === 0 && timeRemaining.minutes === 0 && timeRemaining.hours > 0) {
        timeRemaining.hours = timeRemaining.hours - 1;
        timeRemaining.minutes = 59;
        timeRemaining.seconds = 59;
    } else if (timeRemaining.seconds === 0 && timeRemaining.minutes === 0 && timeRemaining.hours === 0 && timeRemaining.days > 0) {
        timeRemaining.days = timeRemaining.days - 1;
        timeRemaining.hours = 23;
        timeRemaining.minutes = 59;
        timeRemaining.seconds = 59;
    }

    return timeRemaining;
}

class Countdown extends Component {

    intervalHandle = null;

    state = {
        timeRemaining: getCountDownData(new Date('February 11, 2019 00:00:00'))
    };

    countDownOneSecond = () => {
        const timeRemaining = {...this.state.timeRemaining};
        if (timeRemaining.seconds !== 0) {
            timeRemaining.seconds = timeRemaining.seconds - 1;
        } else if (timeRemaining.seconds === 0 && timeRemaining.minutes > 0) {
            timeRemaining.minutes = timeRemaining.minutes - 1;
            timeRemaining.seconds = 59;
        } else if (timeRemaining.seconds === 0 && timeRemaining.minutes === 0 && timeRemaining.hours > 0) {
            timeRemaining.hours = timeRemaining.hours - 1;
            timeRemaining.minutes = 59;
            timeRemaining.seconds = 59;
        } else if (timeRemaining.seconds === 0 && timeRemaining.minutes === 0 && timeRemaining.hours === 0 && timeRemaining.days > 0) {
            timeRemaining.days = timeRemaining.days - 1;
            timeRemaining.hours = 23;
            timeRemaining.minutes = 59;
            timeRemaining.seconds = 59;
        }

        this.setState({ timeRemaining });
    }

    componentDidMount = () => {
        this.intervalHandle = setInterval(() => {
            this.countDownOneSecond();
        }, 1000);
    }

    componentWillUnmount = () => {
        clearInterval(this.intervalHandle);
    }

    render() {
        return (
            <div className="countdown">
                <div className="countdown__section">
                    <p className="countdown__value">
                        { this.state.timeRemaining.days }
                    </p>
                    <p className="countdown__unit">Days</p>
                </div>
                <div className="countdown__section">
                    <p className="countdown__value">
                        { this.state.timeRemaining.hours }
                    </p>
                    <p className="countdown__unit">Hours</p>
                </div>
                <div className="countdown__section">
                    <p className="countdown__value">
                        { this.state.timeRemaining.minutes }
                    </p>
                    <p className="countdown__unit">Mins</p>
                </div>
                <div className="countdown__section">
                    <p className="countdown__value">
                        { this.state.timeRemaining.seconds }
                    </p>
                    <p className="countdown__unit">Secs</p>
                </div>
            </div>
        );
    }

}

export default Countdown;

