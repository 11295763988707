import React from 'react';

const bulletPoint = (props) => {
    const style = {
        backgroundColor: props.color
    };

    return (
        <div className="bulletPoint">
            <div style={style} className="bulletPoint__bullet"></div>
            <span className="bulletPoint__text">{props.text}</span>
        </div>
    );
}

export default bulletPoint;