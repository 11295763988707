import React, { Component } from 'react';
import { document, window } from 'browser-monads';
import Countdown from '../countdown/countdown';
import axios from 'axios';

import image1 from '../../assets/pre-order/1.jpg';
import image2 from '../../assets/pre-order/2.jpg';
import image3 from '../../assets/pre-order/3.jpg';
import image4 from '../../assets/pre-order/4.jpg';
import image5 from '../../assets/pre-order/5.jpg';

import imageSmall1 from '../../assets/pre-order/1-small.jpg';
import imageSmall2 from '../../assets/pre-order/2-small.jpg';
import imageSmall3 from '../../assets/pre-order/3-small.jpg';
import imageSmall4 from '../../assets/pre-order/4-small.jpg';
import imageSmall5 from '../../assets/pre-order/5-small.jpg';

import imageLarge1 from '../../assets/pre-order/1-large.jpg';
import imageLarge2 from '../../assets/pre-order/2-large.jpg';
import imageLarge3 from '../../assets/pre-order/3-large.jpg';
import imageLarge4 from '../../assets/pre-order/4-large.jpg';
import imageLarge5 from '../../assets/pre-order/5-large.jpg';

class HeroPreOrder extends Component {

    numberOfBgImages = 5;
    currentBgImage = 0;
    intervalHandle = null;

    state = {
        classes: ['heroPreOrder'],
        currentScreen: 1,
        formIsValid: false,
        usingSmallImages: false
    }

    nextBg = () => {
        // remove active class from image
        // add active class to next image
        const allImages = document.querySelectorAll('.heroPreOrder__imageBg');
        const image = document.querySelector('.heroPreOrder__imageBg--active');
        image.classList.remove('heroPreOrder__imageBg--active');
        if (this.currentBgImage < this.numberOfBgImages - 1) {
            this.currentBgImage = this.currentBgImage + 1;
        } else {
            this.currentBgImage = 0;
        }
        allImages[this.currentBgImage].classList.add('heroPreOrder__imageBg--active');
    }

    componentDidMount() {
        this.intervalHandle = setInterval(() => {
            this.nextBg();
        }, 4000);
        // window.addEventListener('resize', this.resize);
        // setTimeout(() => {
        //     console.log('resizing');
        // }, 2000);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.resize);
        clearInterval(this.intervalHandle);
    }

    resize = () => {
        if (document.documentElement.clientWidth < 900) {
            this.setState({ usingSmallImages: true })
        } else {
            this.setState({ usingSmallImages: true })
        }
    }

    submitFormData(inputs) {
        const formData = {};
        formData.firstname = inputs[0].value.split(' ')[0];
        formData.lastname = inputs[0].value.split(' ')[1];
        formData.emailaddress = inputs[1].value;
        formData.countrycode = inputs[2].value;
        formData.phonenumber = inputs[3].value;
        if (this.props.conference) {
            formData.atConference = true
        }

        console.log('submitting', formData);

        axios({
            method: 'POST',
            url: "https://millionusers.mylivit.com:8086/preorderSignup",
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });

        this.setState({ currentScreen: 3 });
    }

    showForm = () => {
        this.setState({ currentScreen: 2 });
    }

    isFormValid = (inputs) => {
        for (let input of inputs) {
            if (!input.validity.valid) return false;
        }
        return true;
    }

    showThankYou = (event) => {
        event.preventDefault();
        // if (!this.state.isFormValid) return;
        let inputs = document.querySelectorAll('input[type=text], input[type=email]');
        for (let input of inputs) {
            input.classList.remove('preOrderForm__input--untouched');
        }

        if (this.isFormValid(inputs)) {
            this.submitFormData(inputs)
        }
    }

    removeUntouchedClass = (evt) => {
        evt.target.classList.remove('preOrderForm__input--untouched');
    }

    checkFormValidity = () => {
        let inputs = document.querySelectorAll('input[type=text], input[type=email]');
        this.setState({
            formIsValid: this.isFormValid(inputs)
        });
        console.log(this.state.formIsValid);
    }

    onNameChange = (event) => {
        // this.checkFormValidity();
    }

    onEmailChange = (event) => {
        // this.checkFormValidity();
    }

    onCountryCodeChange = (event) => {
        // this.checkFormValidity();
        let value = event.target.value;
        value = value.replace(/[^0-9\-]/g, '');
        event.target.value = value;
    }

    onPhoneNumberChange = (event) => {
        // this.checkFormValidity();
        let value = event.target.value;
        value = value.replace(/[^0-9\-]/g, '');
        event.target.value = value;
    }

    countDownScreen = (
        <>
            {/* <Countdown /> */}
            {/* Bold Version */}
            {/* <p className="content__offer u-margin-top-very-small">First <span className="content__offer--bold">200 orders</span> are eligible</p>
            <p className="content__offer u-margin-bottom-very-small">to get a LIVIT device at <span className="content__offer--bold">NO COST</span></p> */}
            {/* Non-bold version */}
            <p className="content__phrase">Claim your "Five rights of medication"</p>
            <p className="content__offer u-margin-top-very-small">First 200 orders are eligible</p>
            <p className="content__offer u-margin-bottom-very-small">to get a LIVIT device at NO COST</p>
            <button onClick={this.showForm} className="content__button u-margin-bottom-very-small">PRE-ORDER TODAY</button>
        </>
    );

    formScreen = (
        <>
            <form>
                <label className="preOrderForm__label" htmlFor="name">
                    Full Name
                </label>
                <input type="text"
                    onBlur={(event) => this.removeUntouchedClass(event)}
                    onChange={(event) => this.onNameChange(event)}
                    required
                    id="name"
                    autoComplete="off"
                    placeholder="John Doe"
                    className="preOrderForm__input--untouched preOrderForm__input" />
                <label className="preOrderForm__label" htmlFor="name">
                    Email Address
                </label>
                <input type="email"
                    onBlur={(event) => this.removeUntouchedClass(event)}
                    onChange={(event) => this.onEmailChange(event)}
                    id="email"
                    required
                    autoComplete="off"
                    placeholder="john_doe@email.com"
                    className="preOrderForm__input--untouched preOrderForm__input" />
                <label className="preOrderForm__label" htmlFor="name">
                    Phone Number
                </label>
                <div className="preOrderForm__input-group">
                    <input type="text"
                        onBlur={(event) => this.removeUntouchedClass(event)}
                        onChange={(event) => this.onCountryCodeChange(event)}
                        id="countryCode"
                        autoComplete="off"
                        placeholder="+1"
                        maxLength={3}
                        className="preOrderForm__input preOrderForm__input--untouched preOrderForm__input--country-code" />
                    <input type="text"
                        onBlur={(event) => this.removeUntouchedClass(event)}
                        onChange={(event) => this.onPhoneNumberChange(event)}
                        id="phoneNumber"
                        autoComplete="off"
                        placeholder="(xxx) xxx - xxxx"
                        className="preOrderForm__input--untouched preOrderForm__input" />
                </div>
                {/* <button disabled={!this.state.isFormValid} onClick={(event) => this.showThankYou(event)} */}
                <button onClick={(event) => this.showThankYou(event)}
                    className="content__button u-margin-top-very-small">
                    Submit
                </button>
            </form>
        </>
    )

    getImage(number) {
        const widthCutOff = 900;
        const height = document.documentElement.clientHeight;
        const width = document.documentElement.clientWidth;
        if (number == 1 && !this.state.usingSmallImages) return imageSmall1;
        else if (number == 1) return image1;
        if (number == 2 && !this.state.usingSmallImages) return imageSmall2;
        else if (number == 2) return image2;
        if (number == 3 && !this.state.usingSmallImages) return imageSmall3;
        else if (number == 3) return image3;
        if (number == 4 && !this.state.usingSmallImages) return imageSmall4;
        else if (number == 4) return image4;
        if (number == 5 && !this.state.usingSmallImages) return imageSmall5;
        else if (number == 5) return image5;
    }



    thankYouScreen = (
        <>
            <p className="content__thankYou u-margin-top-very-small">Thank You</p>
            <p className="content__thankYouMessage u-margin-bottom-very-small">
                We'll send you an email with more information shortly. Remember
                to check back in for regular updates on LIVIT and our other
                upcoming products.
            </p>
        </>
    );

    render() {
        const smallMedia = '(max-width: 799px)';
        const ipadVertical = '(max-width: 799px) and (orientation: vertical)';
        const mediumMedia = '(min-width: 800px) and (max-width: 1200px)';
        const largeMedia = '(min-width: 1200px)';

        return (
            <div className={this.state.classes.join(' ')}>
                <picture>
                    <source media={smallMedia} srcSet={imageSmall1} />
                    <source media={mediumMedia} srcSet={image1} />
                    <source media={largeMedia} srcSet={imageLarge1} />
                    <img src={image1} className="heroPreOrder__imageBg heroPreOrder__imageBg--active" />
                </picture>
                <picture>
                    <source media={smallMedia} srcSet={imageSmall2} />
                    <source media={mediumMedia} srcSet={image2} />
                    <source media={largeMedia} srcSet={imageLarge2} />
                    <img src={image2} className="heroPreOrder__imageBg" />
                </picture>
                <picture>
                    <source media={smallMedia} srcSet={imageSmall3} />
                    <source media={mediumMedia} srcSet={image3} />
                    <source media={largeMedia} srcSet={imageLarge3} />
                    <img src={image3} className="heroPreOrder__imageBg" />
                </picture>
                <picture>
                    <source media={smallMedia} srcSet={imageSmall4} />
                    <source media={mediumMedia} srcSet={image4} />
                    <source media={largeMedia} srcSet={imageLarge4} />
                    <img src={image4} className="heroPreOrder__imageBg" />
                </picture>
                <picture>
                    <source media={smallMedia} srcSet={imageSmall5} />
                    <source media={mediumMedia} srcSet={image5} />
                    <source media={largeMedia} srcSet={imageLarge5} />
                    <img src={image5} className="heroPreOrder__imageBg" />
                </picture>
                {/* <img src={this.getImage(1)} className="heroPreOrder__imageBg heroPreOrder__imageBg--active"/> */}
                {/* <img src={this.getImage(2)} className="heroPreOrder__imageBg"/>
                <img src={this.getImage(3)} className="heroPreOrder__imageBg"/>
                <img src={this.getImage(4)} className="heroPreOrder__imageBg"/>
                <img src={this.getImage(5)} className="heroPreOrder__imageBg"/> */}
                <div className="heroPreOrder__content">
                    { this.state.currentScreen === 1 ? this.countDownScreen : null }
                    { this.state.currentScreen === 2 ? this.formScreen : null }
                    { this.state.currentScreen === 3 ? this.thankYouScreen : null }
                </div>
            </div>
        );
    }
};

export default HeroPreOrder;
